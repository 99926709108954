import { isPlatformServer } from '@angular/common';
import { Injectable, PLATFORM_ID, TransferState, inject, makeStateKey } from '@angular/core';
import { Router } from '@angular/router';
import { AudienceType, HostApi, LocalStorageService, PeriodicTaskService, Retry } from '@tytapp/common';
import { environment } from '@tytapp/environment';
import { isClientSide, isOnline, isServerSide } from '@tytapp/environment-utils';
import { BehaviorSubject, Subject } from 'rxjs';
import {
    ApiCommunicationPref, ApiConfiguration, ApiNewsletterOptins, ApiPermissionCheckResult, ApiPostUsersCurrentPermissionsCheck, ApiUser, ApiUserAuthToken, ApiUserSnippet,
    CommunicationPrefsApi, UsersApi
} from '../api';
import { Base64, Cookies, LoggerService, Redirection, RequestParams, Shell, buildQuery } from '../common';

import Bugsnag from '@bugsnag/js';
import jwtDecode from 'jwt-decode';
import { ApiUnauthorizedError } from '@tytapp/api/requester';

const USER_KEY = makeStateKey<ApiUser>('tyt-user');

export type AudienceFilter = (AudienceType | `-${AudienceType}` | 'all')[];

/**
 * Manages the current user and provides services for looking up and managing user accounts.
 *
 * NOTE:
 * WHY WE DO NOT USE LOCALSTORAGE FOR THE AUTH TOKEN:
 * It is far too easy for the cookie and the localStorage to disagree.
 * We should just rely on the cookie as the source of truth as it can
 * be read by both the client side and server side apps
 */
@Injectable()
export class UserService {
    private router = inject(Router);
    private apiConfig = inject(ApiConfiguration);
    private localStorage = inject(LocalStorageService);
    private shell = inject(Shell);
    private cookies = inject(Cookies);
    private requestParams = inject(RequestParams);
    private usersApi = inject(UsersApi);
    private stateTransfer = inject(TransferState);
    private logger = inject(LoggerService);
    private redirection = inject(Redirection);
    private hostApi = inject(HostApi);
    private base64 = inject(Base64);
    private platformId = inject(PLATFORM_ID);
    private commPrefsApi = inject(CommunicationPrefsApi);
    private periodicTasks = inject(PeriodicTaskService);
    private retry = inject(Retry);

    constructor() {
        this.readyPromise = new Promise<void>((res, rej) => this.markReady = res)
            .then(() => {
                this.isReady = true
            });
    }


    private _onboardingChanged = new BehaviorSubject<boolean>(false);
    private _onboarding = false;

    isReady = false;

    get onboardingChanged() {
        return this._onboardingChanged;
    }

    get onboarding() {
        return this._onboarding;
    }

    private _signUpCompleted = new Subject<{ user: ApiUser }>();
    readonly signUpCompleted = this._signUpCompleted.asObservable();

    private _socialSignUpCompleted = new Subject<{ method: 'free-signup' | 'login', user: ApiUser }>();
    readonly socialSignUpCompleted = this._socialSignUpCompleted.asObservable();

    async completeSocialSignup(method: 'free-signup' | 'login', user: ApiUser) {
        await this.sendSocialSignUpBeacons?.(method, user);
        this._socialSignUpCompleted.next({ method, user });
    }

    userToSnippet(user: ApiUser): ApiUserSnippet {
        return <ApiUserSnippet>{
            type: user.type,
            id: user.id,
            uuid: user.uuid,
            display_name: user.display_name,
            username: user.username,
            admin: user.admin,
            super_admin: user.super_admin,
            affiliated: user.affiliate ? true : false,
            staff: user.staff,
            avatar: user.profile ? user.profile.avatar : null
        };
    }

    async hasPermission(check: ApiPostUsersCurrentPermissionsCheck): Promise<boolean> {
        return (await this.checkPermission(check))?.allowed ?? false;
    }

    async checkPermission(check: ApiPostUsersCurrentPermissionsCheck): Promise<ApiPermissionCheckResult> {
        await this.ready;
        try {
            return await this.usersApi.checkPermission(check).toPromise();
        } catch (e) {
            if (e.error === 'unauthenticated')
                return { allowed: false, message: 'Unauthenticated' };

            throw e;
        }
    }

    async refresh() {
        if (isOnline()) {
            try {
                this.user = await this.usersApi.getCurrentUser().toPromise();
            } catch (e) {
                if (isClientSide())
                    this.logger.error(`Failed to refresh current user: ${e.message || e.error || e}`);
            }
        }
    }

    validateEmail(email: string) {
        if (!email)
            return 'Please enter the email address you would like to use.';

        if (email.endsWith('.con'))
            return 'The email you provided ends with ".con". You probably meant ".com"';

        if (email.endsWith('.comm'))
            return 'The email you provided ends with ".comm". You probably meant ".com"';

        if (email.endsWith('.ccom'))
            return 'The email you provided ends with ".ccom". You probably meant ".com"';

        if (email.includes('@gmail') && !email.endsWith('.com'))
            return 'Looks like you have a GMail address but you have specified the wrong domain name. Check the email address you\'ve entered for errors. ';

        return null;
    }

    set onboarding(value) {
        if (value === this._onboarding)
            return;

        this._onboarding = value;
        this._onboardingChanged.next(value);
        if (isClientSide())
            this.localStorage.set('onboarding', value);
    }

    private _user: ApiUser;

    public firstTime: boolean = true;

    userChanged: BehaviorSubject<ApiUser> = new BehaviorSubject<ApiUser>(null);
    guestChanged: BehaviorSubject<ApiUser> = new BehaviorSubject<ApiUser>(null);
    identityChanged: BehaviorSubject<ApiUser> = new BehaviorSubject<ApiUser>(null);

    sourceChanged: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    markReady: Function;
    readyPromise: Promise<void>;
    private _bounceUrl: string;

    get bounceURL() {
        if (isClientSide()) {
            let storedBounceUrl = localStorage['tyt.userService.bounceUrl'];
            if (storedBounceUrl)
                this._bounceUrl = storedBounceUrl;
        }
        return this._bounceUrl;
    }

    set bounceURL(value) {
        this._bounceUrl = value;
        if (isClientSide()) {
            if (value)
                localStorage['tyt.userService.bounceUrl'] = value;
            else
                delete localStorage['tyt.userService.bounceUrl'];
        }

        this.logger.info(`UserService: Return URL is ${value}`);
    }

    get identityKey() {
        if (this.user)
            return `user_${this.user.id}`;

        return `anonymous`;
    }

    get ready() {
        return this.readyPromise;
    }

    get token(): string {
        return <string>this.apiConfig.accessToken;
    }

    set token(value) {
        this.apiConfig.accessToken = value;
    }

    get sourceFirstTime(): boolean {
        if (isClientSide())
            return <boolean>this.localStorage.get('trafficSourceFirstTime');

        return true;
    }

    private _sourceStamp: Date;

    get sourceStamp(): Date {
        if (isClientSide()) {
            if (!this._sourceStamp) {
                this._sourceStamp = new Date(this.localStorage.get('trafficSourceStamp'));
            }

            return this._sourceStamp;
        }

        return undefined;
    }

    decodeToken(token): any {
        return jwtDecode(token);
    }

    async refreshToken() {

        if (!this._user || !this.apiConfig.accessToken)
            return;

        let tokenStr = this.apiConfig.accessToken;
        let token = this.decodeToken(tokenStr);

        // Determine the appropriate renewal period before expiry

        let threshold = 60 * 60 * 8;

        if (token.renewalTime)
            threshold = token.renewalTime;
        else if (token.lifetime)
            threshold = Math.min(threshold, Math.round(token.lifetime * 0.25));

        let renewalTime = token.exp - threshold;

        if ((new Date().getTime() / 1000) < renewalTime)
            return;

        this.logger.info("Refreshing authentication...");
        let userAuthToken: ApiUserAuthToken;

        try {
            userAuthToken = await this.usersApi.refreshAuthentication(token.rememberMe || false).toPromise();
        } catch (e) {
            if (e.json)
                e = e.json();

            this.logger.error(`Failed to refresh auth token: ${e.error || e.message}`);
            this.logger.error(e);
            return;
        }

        token = this.decodeToken(userAuthToken.token);
        let timeMs = 1000 * (token.exp - token.renewalTime);

        this.logger.info(`Authentication refreshed. Renewal will happen at ${new Date(timeMs)}`);
        this.saveAuthentication(userAuthToken, userAuthToken.token);
    }

    async refreshTokenPeriodically() {

        await this.refreshToken();

        // Do not wait to refresh token for server-side rendering.
        // (Infinite loads)

        if (isClientSide()) {
            this.periodicTasks.scheduleOnce(this._tokenRefreshInterval, () => {
                this.refreshTokenPeriodically();
            });
        }
    }

    private _tokenRefreshInterval: number = 1000 * 60 * 30;
    private _postLoginUrl: string = null;
    private _postLoginAction: Function = null;
    private _source: string = null;

    set postLoginUrl(value: string) {
        if (value != null)
            value = value.replace(/^\/+/, '');

        this._postLoginUrl = value;
        this._postLoginAction = null;
    }

    get postLoginUrl() {
        return this._postLoginUrl;
    }

    requireLogin() {
        if (!this.user) {
            this.router.navigate(['login']);
            return false;
        }

        return true;
    }

    get decodedToken() {
        return jwtDecode<{ type: 'Guest' | 'User', sub: string }>(this.token);
    }

    /**
     * Do not navigate after login is complete. Used for inline signups.
     */
    stayAfterLogin() {
        this.afterLogin(() => { });
    }

    /**
     * Run the given function after login (and do not otherwise navigate)
     * @param action
     */
    afterLogin(action: Function) {
        this._postLoginAction = action;
        this._postLoginUrl = null;
    }

    private _initialized = false;

    private async authenticateAsGuest() {
        try {
            let guest = await this.usersApi.authenticate({
                method: 'guest'
            }).toPromise();

            this.saveAuthentication(guest, guest.token);
        } catch (e) {
            this.logger.error(`Failed to authenticate as guest`, e);
        }
    }

    async init() {
        if (this._initialized)
            return;
        this._initialized = true;

        if (isClientSide()) {

            window.addEventListener('online', async () => {
                if (!this.token) {
                    await this.authenticateAsGuest();
                }
            });

            try {
                if (typeof BroadcastChannel !== 'undefined') {
                    this.broadcastChannel = new BroadcastChannel('user-state');
                    this.broadcastChannel.addEventListener('message', ev => {
                        if (ev.data.type === 'user-changed') {
                            this.token = ev.data.token;
                            this.hostApi.sendMessage({ type: 'auth_token_changed', token });
                            this.locallyUpdateUser(ev.data.user, false);
                        }
                    })
                }
            } catch (e) {
                this.logger.error(`[UserService] Failed to set up BroadcastChannel! Error:`);
                this.logger.error(e);
            }
        }

        if (isClientSide() && this.localStorage.get('onboarding')) {
            this.onboarding = true;
        }

        let repeatVisitorCookie = this.cookies.get('repeatVisitor');
        if (repeatVisitorCookie) {
            this.firstTime = false;
        } else {
            this.firstTime = true;
            if (isClientSide())
                this.localStorage.set('repeatVisitor', "1");
            this.cookies.set('repeatVisitor', 1, 1000 * 60 * 60 * 24 * 365 * 5);
        }

        // TODO: swap out with equivalent Location service (this.location)
        let token: string = null;

        if (isClientSide()) {
            let args = this.requestParams.params;
            if (args.token) {
                this.logger.info(`Storing passed token ${args.token}`);
                this.cookies.set(environment.auth.cookie, { token: args.token });

                // Remove the token from the current URL to avoid
                // leaking tokens if the URL is shared

                let newUrl = location.href.replace(/token=[^&]+/, 'tx=');
                history.replaceState({}, document.title, newUrl);
            }

            if (args._usid) {
                let newSource = args._usid;
                let currentSource = this.source;
                if (newSource != currentSource) {
                    if (this.source)
                        this.logger.info(`Traffic source changed from ${currentSource} to ${newSource}`);
                    else
                        this.logger.info(`Traffic source: ${newSource}`);

                    this.source = newSource;
                }
            }
        }

        let authCookie = this.cookies.get<any>(environment.auth.cookie);
        let authCookieEnabled = true;

        if (isClientSide() && window['disableCookieAuthentication'])
            authCookieEnabled = false;

        if (authCookieEnabled && authCookie && !token)
            token = authCookie.token;

        if (!token && isOnline()) {
            await this.authenticateAsGuest();
        }

        this.token = token;
        this.hostApi.sendMessage({ type: 'auth_token_changed', token });

        if (authCookieEnabled && this.apiConfig.accessToken)
            this.cookies.set(environment.auth.cookie, { token: this.apiConfig.accessToken });

        let user: ApiUser;

        if (token) {
            let jwt = jwtDecode<{ type: 'Guest' | 'User', sub: string }>(token);

            this.logger.info(`Signed in as ${jwt.type} ${jwt.sub}`);

            if (isClientSide())
                user ??= this.localStorage.get('user');

            // Set the current user early to get the app booting while we refresh it.

            if (user)
                this.user = user;

            // Fetch/refresh

            if (isOnline()) {
                try {
                    user = await this.retry.standard(async () => this.usersApi.getCurrentUser().toPromise());
                } catch (e) {
                    // The only time we'll invalidate the user's token is when we receive a true "unauthenticated"
                    // error from the server, indicating that our authentication token was rejected. All other kinds
                    // of errors are likely server errors, so we shouldn't make the user log back in on our account.
                    debugger;
                    if (e instanceof ApiUnauthorizedError) {
                        this.logger.error(`Failed to fetch user: Auth token expired.`);
                        this.completeLogout();
                        this.router.navigate(['login']);
                        return;
                    } else {
                        this.logger.error(`Failed to fetch user`, e);
                    }
                }
            } else {
                this.logger.info(`[UserService] Skipping user refresh: Client is offline.`);
            }

            // If we don't have a cached user, we could not fetch the user from the API, then we have no choice
            // but to log the user out.

            if (!user) {
                this.logger.error(`FATAL: Failed to fetch user after several attempts! Is Platform down?`);
                token = null;
                this.router.navigate(['login']);
                return;
            }
        }

        if (user?.disabled) {
            token = null;
            user = null;
        }

        this.saveAuthentication(user, token);
        this.markReady();
        this.refreshTokenPeriodically();
        this.fetchAvailableAccounts();

    }

    resetSource() {
        if (isClientSide()) {
            this.localStorage.remove('trafficSource');
            this.localStorage.remove('trafficSourceFirstTime');
        }
        this.source = null;
    }

    saveAuthentication(user: ApiUser, token: string) {
        let jwt: { type: 'User' | 'Guest', sub: string };

        if (token)
            jwt = jwtDecode<{ type: 'User' | 'Guest', sub: string }>(token);

        if (!token) {
            this.token = null;
            this.hostApi.sendMessage({ type: 'auth_token_changed', token: null });
            if (isClientSide())
                this.localStorage.remove('user');
            this.cookies.remove(environment.auth.cookie);
            this.cookies.remove(environment.auth.userCookie);
            return;
        }

        if (token && environment.showDevTools) {
            this.logger.info(`Saved auth for ${jwt.type} ${jwt.sub}`);
        }

        let authCookieEnabled = true;
        if (isClientSide() && window['disableCookieAuthentication'])
            authCookieEnabled = false;

        this.token = token;
        this.hostApi.sendMessage({ type: 'auth_token_changed', token });
        if (isClientSide())
            this.localStorage.set('user', user);
        if (authCookieEnabled && this.apiConfig.accessToken) {
            this.cookies.set(environment.auth.cookie, { token });
            this.cookies.set(environment.auth.userCookie, jwt.sub, null, true);
        }
        this.user = user;
    }

    sendSignUpBeacons: (user: ApiUser) => Promise<void>;
    sendSocialSignUpBeacons: (method: 'login' | 'free-signup', user: ApiUser) => Promise<void>;

    async completeSignUp(user: ApiUser, token: string, optins: ApiNewsletterOptins) {
        // Enable onboarding state so that we show C2As for further setting up our account.
        this.onboarding = true;

        // Save authentication first (before completeLogin) so that sendRegisteredBeacons
        // knows what user just signed up
        this.saveAuthentication(user, token);
        await this.sendSignUpBeacons?.(user);
        this.completeLogin(user, token);
        await this.saveCommPrefs(optins);
        this._signUpCompleted.next({ user });
    }

    completeLogin(user: ApiUser, token: string, hideDialogs = true, defaultAction = null) {
        this.saveAuthentication(user, token);
        this.addAvailableAccount({
            avatarUrl: user.profile?.avatar,
            displayName: user.display_name,
            email: user.email,
            id: user.id,
            username: user.username,
            uuid: user.uuid,
            staff: user.staff,
            token
        });

        if (hideDialogs)
            this.shell.hideDialog();

        setTimeout(() => {
            if (this._postLoginAction) {
                this._postLoginAction();
            } else if (this.postLoginUrl) {
                let destination = this.postLoginUrl;
                this.postLoginUrl = null;
                this.router.navigateByUrl(destination);
            } else if (defaultAction) {
                defaultAction();
            } else {
                this.redirection.go('/login/return');
                // this.router.navigate(['login/return']);
            }
        }, 100);
    }

    async completeLogout(removeAvailableAccounts = true) {
        if (removeAvailableAccounts)
            this.removeAllAvailableAccounts();

        let guestToken: string;

        if (isOnline()) {
            try {
                this.authenticateAsGuest();
            } catch (e) {
                this.logger.error(`Failed to obtain a guest token during logout!`);
                this.logger.error(e);
                this.saveAuthentication(null, null);
            }
        } else {
            this.saveAuthentication(null, null);
        }

    }

    async assume(token) {
        if (!token)
            throw new Error("Cannot assume a user without an access token.");

        this.logger.info(`Assuming user with token ${token}`);

        let originalToken = null;

        if (this.token)
            originalToken = this.token;

        // Store the previous authentication details

        this.token = token;
        this.hostApi.sendMessage({ type: 'auth_token_changed', token });

        let user: ApiUser;
        this.logger.info(`Fetching the assumed user from API...`);
        user = <any>await this.usersApi.getCurrentUser().toPromise();

        this.logger.info(`User assumption: Success: Now logged in as ${user.email} (${user.uuid})`);

        user['assumed'] = true;
        this.completeLogin(user, token, false, () => { });
        if (isClientSide())
            this.localStorage.set('originalToken', originalToken);
    }

    get source() {
        if (!this._source && isClientSide()) {
            if (this.localStorage.get('trafficSource')) {
                this._source = <string>this.localStorage.get('trafficSource');
            }
        }

        return this._source;
    }

    get recentSource() {
        let time = this.sourceStamp || new Date();
        if (time.getTime() + 1000 * 60 * 60 * 3 < Date.now()) // is expired?
            return null;

        return this.source;
    }

    set source(value: string) {
        this.logger.info(`Setting attribution source to '${value}'`);
        this._source = value;
        this._sourceStamp = new Date();

        if (isClientSide()) {
            this.localStorage.set('trafficSource', value);
            this.localStorage.set('trafficSourceFirstTime', this.firstTime);
            this.localStorage.set('trafficSourceStamp', this._sourceStamp.toISOString());
        }

        this.sourceChanged.next(value);
    }

    get loggedIn() {
        return this.user != null && this.apiConfig.accessToken != null;
    }

    userUpdatedAt: number;

    set user(user: ApiUser) {
        this.userUpdatedAt = Date.now();
        this._user = user;

        this.locallyUpdateUser(user);
        if (isPlatformServer(this.platformId))
            this.stateTransfer.set<ApiUser>(USER_KEY, user);
    }

    goToLogin(returnURL?: string) {
        if (!returnURL)
            returnURL = isClientSide() ? location.href : '/';
        if (returnURL.startsWith('/'))
            returnURL = `${environment.urls.root}${returnURL}`;

        this.redirection.go(`/login?${returnURL ? `return=${encodeURIComponent(returnURL)}` : ``}`);
    }

    /**
     * Apply a new user object to the entire application.
     * This does not change login state, you must only
     * pass a modified version of the LOGGED IN USER here
     * or else bad things will happen!
     */
    locallyUpdateUser(user: ApiUser, sendBroadcast = true) {
        this._user = user;

        // Log out the change

        if (isClientSide()) {
            if (user?.type === 'User') {
                this.logger.info(`User ${user.email} [${user.uuid}] is signed in.`);
            } else if (user?.type === 'Guest') {
                this.logger.info(`Guest ${user.uuid} is signed in.`);
            } else if (user) {
                this.logger.info(`Identity [${user.type}] ${user.uuid} is signed in.`);
            } else {
                this.logger.info(`User is now signed out.`);
            }
        }

        if (isServerSide()) {
            try {
                this.logger.error(`Setting DD APM user...`);
                require((() => 'dd-trace')()).setUser({
                    id: user.uuid,
                    email: user.email,
                    name: user.display_name,
                    role: user.staff ? 'staff' : 'end-user'
                });
            } catch (e) {
                this.logger.error(`Failed to set DD APM user`, e);
            }
        }

        // Let Bugsnag know about the user change

        if (isClientSide()) {
            if (user) {
                Bugsnag.setUser(
                    user.uuid,
                    user.email,
                    user.display_name
                );
            } else {
                Bugsnag.setUser(
                    'logged-out',
                    'logged-out@tyt.com',
                    'Logged Out'
                );
            }
        } else {
            let requestContext = Zone.current.get('requestContext');
            if (requestContext) {
                requestContext.user = {
                    id: this._user?.uuid,
                    email: this._user?.email,
                    name: this._user.type === 'Guest' ? `Guest` : this._user?.username
                }
            }
        }

        // Notify all subscriptions

        this.identityChanged.next(this._user);

        if (this._user) {
            if (this._user.type === 'Guest') {
                this.guestChanged.next(this._user);
                this.userChanged.next(null);
            } else {
                this.userChanged.next(this._user);
                this.guestChanged.next(null);
            }
        } else {
            this.userChanged.next(null);
            this.guestChanged.next(null);
        }

        // Add user information to the current request if we are in SSR (used in Bugsnag error reporting)

        // Notify other tabs

        if (sendBroadcast && isClientSide() && this.broadcastChannel) {
            this.broadcastChannel.postMessage({ type: 'user-changed', user: this._user, token: this.token });
        }

    }

    broadcastChannel: BroadcastChannel;

    get isLoggedIn() {
        return this._user != null;
    }

    get identity() {
        return this._user;
    }

    get user() {
        if (this._user?.type === 'User')
            return this._user;
        else
            return null;
    }

    get guest() {
        if (this._user?.type === 'User')
            return this._user;
        else
            return null;
    }

    /**
     * True if the current user identity is entitled (either User or Guest).
     */
    get entitled() {
        return this._user?.entitled;
    }


    matchesAudience(audiences: AudienceFilter) {
        if (!audiences)
            return true;

        if (audiences.includes('all')) {
            return !audiences.includes(`-${this.audienceType}`);
        } else {
            return audiences.includes(this.audienceType);
        }
    }

    get audienceType(): AudienceType {
        // Note that _user is different than user here (_user will be set even if the identity is a Guest)

        if (this._user?.entitled)
            return 'member';

        if (this._user?.membership)
            return 'expired member';

        if (this.user)
            return 'registered';

        return 'visitor';
    }

    private _availableAccountsChanged = new BehaviorSubject<AvailableAccount[]>([]);
    private _availableAccountsChanged$ = this._availableAccountsChanged.asObservable();
    get availableAccountsChanged() { return this._availableAccountsChanged$; }

    private _availableAccounts: AvailableAccount[] = [];
    get availableAccounts() { return this._availableAccounts.slice(); }

    get switchAccountsKey() {
        return `tyt:accounts:${environment.apiOverride ?? 'default'}`;
    }

    private fetchAvailableAccounts() {
        if (isServerSide() || !localStorage[this.switchAccountsKey]) {
            this._availableAccountsChanged.next([]);
            this._availableAccounts = [];
            return [];
        }

        try {
            let accounts = JSON.parse(this.base64.decode(localStorage[this.switchAccountsKey]));
            this._availableAccountsChanged.next(accounts);
            this._availableAccounts = accounts;
            return accounts;
        } catch (e) {
            this.logger.error(`Failed to parse localStorage[${this.switchAccountsKey}]: ${e.message}`);
            this.logger.error(`Contents was: '${localStorage[this.switchAccountsKey]}'`);
            delete localStorage[this.switchAccountsKey];
            return [];
        }
    }

    addAvailableAccount(account: AvailableAccount) {
        if (isServerSide())
            return;
        let accounts = this.fetchAvailableAccounts();

        accounts = accounts.filter(x => x.id !== account.id);
        accounts.unshift(account);

        localStorage[this.switchAccountsKey] = this.base64.encode(JSON.stringify(accounts));
        this.fetchAvailableAccounts();
    }

    removeAvailableAccount(account: AvailableAccount) {
        if (isServerSide())
            return;
        let accounts = this.fetchAvailableAccounts();
        accounts = accounts.filter(x => x.id !== account.id);
        localStorage[this.switchAccountsKey] = this.base64.encode(JSON.stringify(accounts));
        this.fetchAvailableAccounts();
    }

    removeAllAvailableAccounts() {
        if (isServerSide())
            return;
        delete localStorage[this.switchAccountsKey];
        this.fetchAvailableAccounts();
    }

    isCurrentlySignedIntoAvailableAccount(account: AvailableAccount) {
        if (this.user?.id === account.id)
            return true;
        return false;
    }

    async switchToAvailableAccount(account: AvailableAccount) {
        this.token = account.token;
        this.hostApi.sendMessage({ type: 'auth_token_changed', token: account.token });

        this.logger.info(`Fetching the assumed user from API...`);
        let user = await this.usersApi.getCurrentUser().toPromise();

        this.logger.info(`[Account Switcher]: Success: Now logged in as ${user.email} (${user.uuid})`);

        this.completeLogin(user, account.token, false, () => { });
    }

    async saveCommPrefs(optins: ApiNewsletterOptins, provider = 'email') {
        if (Object.keys(optins).length === 0)
            return;

        try {
            const patch: ApiCommunicationPref[] = Object.entries(optins).map(
                ([provider_id, allowed]) => ({
                    provider_id,
                    provider,
                    allowed
                })
            );
            await this.commPrefsApi.patchCommunicationPrefs({
                communication_prefs: patch
            }).toPromise();
        } catch (e) {
            this.logger.error('Failed to save communication preferences: ');
            this.logger.error(e);
        }
    }

    getPublicProfile(username: string) {
        return this.usersApi.getPublicProfile(username).toPromise();
    }

    private parseEncodedCommPrefs(commPrefs: string) {
        if (!commPrefs) {
            return {};
        }

        return <ApiNewsletterOptins>Object.fromEntries(
            commPrefs
                .split(',')
                .map(k => [k.replace(/^-/, ''), !k.startsWith('-')])
        );
    }

    async saveEncodedCommPrefs(commPrefs: string) {
        if (this.user) {
            await this.saveCommPrefs(this.parseEncodedCommPrefs(commPrefs));
        }
    }

    encodeCommPrefs(commPrefs: ApiNewsletterOptins) {
        return Object.entries(commPrefs)
            .map(([k, v]) => `${v ? '' : '-'}${k}`)
            .join(',');
    }

    /**
     * Generate an OAuth sign in URL for the given provider, with the given options.
     * This URL will be of the form /sign-in-with/:provider?${options}. It will
     * @param provider
     * @param options
     * @returns
     */
    oauthUrlFor(
        provider: string,
        options?: {
            communicationsOptIn?: boolean,
            source?: string,
            defaultSource?: string,
            analyticsMethod?: string,
            commPrefs?: ApiNewsletterOptins
        }
    ) {
        options ??= {}

        return `/sign-in-with/${provider}?${buildQuery({
            app: 'web',
            source: options.source ?? this.recentSource ?? options.defaultSource,
            source_first_time: this.sourceFirstTime ? 'true' : 'false',
            communications_opt_in: options.communicationsOptIn ?? false,
            return_url: `/login/return?${buildQuery({
                method: options.analyticsMethod,
                commPrefs: this.encodeCommPrefs(options.commPrefs ?? {})
            })}`
        })}`;
    }

    isOAuthProviderEnabled(provider: string) {
        return this.hostApi.hasCapabilitySync(`platform:sign_in_provider:${provider}`)
    }
}

export interface AvailableAccount {
    id: number;
    uuid: string;
    displayName: string;
    username: string;
    email: string;
    avatarUrl: string;
    token: string;
    staff: boolean;
}
